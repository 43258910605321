import { Controller } from "stimulus"
import Cropper from 'cropperjs';

export default class extends Controller {
  static targets = ['input', 'imagePreview', 'croppedUrl']

  connect() {
    this.cropper = undefined
    this.modal = $('#modal')
  }

  showModal(e, image) {
    this.cropper = new Cropper(image, {
      viewMode: 2,
      aspectRatio: 3000 / 900,
      zoomable: true
    });
    e.stopImmediatePropagation();
  }

  hideModal(e) {
    this.cropper.destroy();
    this.cropper = undefined;
    e.stopImmediatePropagation();
  }

  previewImageSelected() {
    const files = this.inputTarget.files
    const image = this.imagePreviewTarget

    if (files) {
      const reader = new FileReader();
      reader.onload = () => {
        image.src = reader.result;
        this.modal.modal('show')
      }
      reader.readAsDataURL(files[0]);
    }

    this.modal.on('shown.bs.modal', (e) => {this.showModal(e, image)}
    ).on('hidden.bs.modal',  (e) => {this.hideModal(e)});
  }

  crop() {
    const canvas = this.cropper.getCroppedCanvas({
      width:3000,
      height:900
    });

    const url = canvas.toDataURL()
    this.croppedUrlTarget.value = url
    this.modal.modal('hide');
  }
}
