import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ 'numberInput' ];

  connect() {
    this.allowKeypress();
  }

  allowKeypress() {
    const inputs = this.numberInputTargets;

    inputs.forEach(function (input) {
      if(input) {
        // Allow only numeric keys (0-9) / '<' and '>'
        input.addEventListener("keydown", (event) => {
          if (!((event.key >= "0" && event.key <= "9") ||
                 event.key === "ArrowLeft" || event.key === "ArrowRight" ||
                 event.key === "Backspace" ||
                 event.key === "Delete" ))
          {
            event.preventDefault();
          }
        });
      }
    })

    // input.onkeydown = (e) => {
    //   if (e.which === 38 || e.which === 40) {
    //     e.preventDefault();
    //   }
    // }
  }

}
