import { Controller } from "stimulus";
import { csrfToken } from "@rails/ujs";

export default class extends Controller {
  static targets = ["form", "events"]

  filter(event) {
    event.preventDefault()

    fetch(this.formTarget.action, {
      method: "POST",
      headers: { "Accept": "text/plain", "X-CSRF-Token": csrfToken() },
      body: new FormData(this.formTarget)} )
      .then(response => response.text())
      .then((data) => {
        this.eventsTarget.innerHTML = data
      });
  }

};
