const copyLinkToClipboard = (e) => {
  e.preventDefault();
  const cb = navigator.clipboard;
  const link = e.currentTarget.href;
  cb.writeText(link).then(() => alert('Link copiado'));
}

const initClipboard = () => {
  const generators = document.querySelectorAll('.generate-link');
  generators.forEach((generator) => {
    generator.addEventListener('click', copyLinkToClipboard)
  })
}

export {initClipboard}
