import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["typeInput", "originLabel", "destinationLabel", "departureLabel", "arrivalLabel"]

  connect() {
    this.checkTypeInput();
  }

  checkTypeInput() {
    if (this.typeInputTarget.value === 'Aluguel de carro') {
      this.changeCarLabels();
    }
    else {
      this.changeNotCarLabels();
    }
  }

  changeCarLabels() {
    this.originLabelTarget.innerHTML = "Local de Retirada: <abbr title=\"obrigatório\">*</abbr>";
    this.destinationLabelTarget.innerHTML = "Local de Devolução: <abbr title=\"obrigatório\">*</abbr>";
    this.departureLabelTarget.innerHTML = "Data e Hora da Retirada:";
    this.arrivalLabelTarget.innerHTML = "Data e Hora da Devolução:";
  }

  changeNotCarLabels() {
    this.originLabelTarget.innerHTML = "Origem: <abbr title=\"obrigatório\">*</abbr>";
    this.destinationLabelTarget.innerHTML = "Destino: <abbr title=\"obrigatório\">*</abbr>";
    this.departureLabelTarget.innerHTML = "Data e Hora da Partida:";
    this.arrivalLabelTarget.innerHTML = "Data e Hora da Chegada:";
  }
}
