const templateForm = event => {
    const checkboxTemplate = document.getElementById("itinerary_template_true");
    const checkboxItinerary = document.getElementById("itinerary_template_false");
    const ndays = document.querySelector(".template-ndays-input");
    const yearbegin = document.getElementById("itinerary_date_begin_1i");
    const monthbegin = document.getElementById("itinerary_date_begin_2i");
    const daybegin = document.getElementById("itinerary_date_begin_3i");
    const yearend = document.getElementById("itinerary_date_end_1i");
    const monthend = document.getElementById("itinerary_date_end_2i");
    const dayend = document.getElementById("itinerary_date_end_3i");
    const template = document.querySelector(".template-form-ndays");
    const notTemplate = document.querySelector(".not-template-form");
    if(checkboxTemplate){
        checkboxTemplate.addEventListener("click", event => {
            if(checkboxTemplate.checked){
                template.style.display = 'block';
                notTemplate.style.display = 'none';
                yearbegin.value = '';
                monthbegin.value = '';
                daybegin.value = '';
                yearend.value = '';
                monthend.value = '';
                dayend.value = '';
            }
        });
    }if(checkboxItinerary){
        checkboxItinerary.addEventListener("click", event => {
            if(checkboxItinerary.checked){
                template.style.display = 'none';
                notTemplate.style.display = 'block';
                ndays.value = '';
            }
        });
    }
};

export { templateForm };
