import { Controller } from "stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  static targets = ['origin', 'destiny', 'secondOrigin', 'thirdOrigin', 'fourthOrigin', 'secondDestiny', 'thirdDestiny', 'fourthDestiny']

  // Define static value to fetch the URL from HTML data attributes.
  static values = { url: String,
                    options: Object,
                    origin: Number,
                    destiny: Number  };

  // Triggered when the Stimulus controller is connected to the DOM.
  connect() {
    this.initializeTomSelect();
  }

  // Initialize the TomSelect dropdown with the desired configurations.
  initializeTomSelect() {
    // Return early if no element is associated with the controller.
    if (!this.element) return;

    // Create a new TomSelect instance with the specified configuration.
    // value, label, search, placeholder, etc can all be passed as static values instead of hard-coded.
    let origin = new TomSelect(this.originTarget,
      {
      items: [this.originValue],
      onFocus:function() {
        origin.clear();
      }
    });

    let destiny = new TomSelect(this.destinyTarget,
      {
        items: [this.destinyValue],
        onFocus:function() {
          destiny.clear();
      },

    });

    let secondOrigin = new TomSelect(this.secondOriginTarget,
      {
      items: [this.originValue],
      onFocus:function() {
        secondOrigin.clear();
      }
    });

    let secondDestiny = new TomSelect(this.secondDestinyTarget,
      {
        items: [this.destinyValue],
        onFocus:function() {
          secondDestiny.clear();
      },
    });

    let thirdOrigin = new TomSelect(this.thirdOriginTarget,
      {
      items: [this.originValue],
      onFocus:function() {
        thirdOrigin.clear();
      }
    });

    let thirdDestiny = new TomSelect(this.thirdDestinyTarget,
      {
        items: [this.destinyValue],
        onFocus:function() {
          thirdDestiny.clear();
      },
    });

    let fourthOrigin = new TomSelect(this.fourthOriginTarget,
      {
      items: [this.originValue],
      onFocus:function() {
        fourthOrigin.clear();
      }
    });

    let fourthDestiny = new TomSelect(this.fourthDestinyTarget,
      {
        items: [this.destinyValue],
        onFocus:function() {
          fourthDestiny.clear();
      },
    });
  }
}
