// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('@client-side-validations/client-side-validations')
require('../plugins/simple-form.bootstrap4')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


// ----------------------------------------------------
// Note(lewagon): ABOVE IS RAILS DEFAULT CONFIGURATION
// WRITE YOUR OWN JS STARTING FROM HERE 👇
// ----------------------------------------------------

// External imports
import "bootstrap";
import 'select2/dist/css/select2.css';
import "chartkick/chart.js"

import { activeSide } from "../components/itinerarySideright"
import { initPlaces } from "../components/places"
import { templateForm } from "../components/itineraryForm"
import { initFlatpickr, initDatepicker } from "../components/flatpickr";
import { initPrint } from "../components/print";
import { initCopy } from "../components/copy";
import { previewImageOnFileSelect } from "../components/photo_preview";
import { multipleSelect } from "../components/select";
import { flightOption, handleFlightForm } from "../components/flight_stats"
import { initSortable } from "../plugins/init_sortable"
import { initStarRating } from '../plugins/init_star_rating';
import { initClipboard } from "../components/clipboard";
import { initTagManager, initGoogleAnalytics } from '../plugins/init_tag_manager';
import { initHotjar } from '../plugins/init_hotjar'

import { Portuguese } from "flatpickr/dist/l10n/pt.js"

// Internal imports, e.g:
// import { initSelect2 } from '../components/init_select2';
window.$ = $;

// To get the functions on the jQuery modals
window.previewImageOnFileSelect = previewImageOnFileSelect;
window.Portuguese = Portuguese;
window.flightOption = flightOption;
window.handleFlightForm = handleFlightForm;
window.initPlaces = initPlaces;

document.addEventListener('turbolinks:load', () => {
  // Call your functions here, e.g:
  // initSelect2();
  activeSide();
  initPlaces();
  templateForm();
  initFlatpickr();
  initPrint();
  initCopy();
  previewImageOnFileSelect();
  multipleSelect();
  flightOption();
  handleFlightForm();
  initSortable();
  initStarRating();
  initClipboard();
  initTagManager();
  initGoogleAnalytics();
  initHotjar();
});

require("trix")
require("@rails/actiontext")
import "controllers"
