const initCopy = () => {
  const copyBtn = document.getElementById('copy-btn');
  if (copyBtn) {
    copyBtn.addEventListener('click', (event) => {
      const cb = navigator.clipboard;
      const link = document.getElementById("iframe-url").textContent;
      cb.writeText(link).then(() => alert('Link copiado'));
    });
  }
  const copyIframeBtn = document.getElementById('copy-iframe-btn');
  if (copyIframeBtn) {
    copyIframeBtn.addEventListener('click', (event) => {
      const cb = navigator.clipboard;
      const link = document.getElementById("textarea").textContent;
      cb.writeText(link).then(() => alert('Roteiro copiado'));
    });
  }
}

export { initCopy }
