const flightOption = () => {
  const flightInput = document.getElementById('travel_kind');
  const searchFlightBtn = document.getElementById('flight');
  
  if (flightInput) {
    flightInput.addEventListener("change", (event) => {
      if (event.target.value === "Avião") {
        searchFlightBtn.classList.remove("invisible");
      } else {
        searchFlightBtn.classList.add("invisible");
      }
    });
  }
}

const fetchFlightStats = (company_code, flight_number, day, month, year) => {
  const results = document.getElementById('fligh-results');
  const url = `${window.location.origin}/fetch_flight?company=${company_code}&fly_number=${flight_number}&year=${year}&month=${month}&day=${day}`
  fetch(url)
  .then(response => response.json())
  .then((data) => {
    results.innerHTML = "";
    if (data[0][0] == 'error') {
      const flightCard =
        `<div class="flight-card">
          <div class="flight-info">
            <h6><strong>${data[0][1]}</h6>
          </div>
        </div>`
      results.insertAdjacentHTML("beforeend", flightCard);
    } else if (data[1][1].length === 0) {
        const flightCard =
          `<div class="flight-card">
            <div class="flight-info">
              <h6><strong>Não encontramos nenhum voo</h6>
            </div>
          </div>`
        results.insertAdjacentHTML("beforeend", flightCard);
    } else {
      data[1][1].forEach((result) => {
        const flightCard =
          `<div class="flight-card">
            <div class="flight-info">
              <h6><strong>Origem: ${result.departureAirportFsCode} - Destino: ${result.arrivalAirportFsCode}</strong></h6>
              <h6>Empresa: ${result.carrierFsCode}</h6>
              <h6>Número do Voo: ${result.flightNumber} </h6>
              <h6>Horário de Partida: ${result.departureTime.split('T')[0]} ${result.departureTime.split('T')[1].split('.000')[0]}</h6>
              <h6>Horário de Chegada: ${result.arrivalTime.split('T')[0]} ${result.arrivalTime.split('T')[1].split('.000')[0]}</h6>
              <h6>Escala: ${result.stops}</h6>
            </div>
            <div class="flight-action">
              <button id="${result.flightNumber}" data-flight="${result.flightNumber}" data-stops="${result.stops}" data-departure="${result.departureAirportFsCode}" data-arrival="${result.arrivalAirportFsCode}" data-time-departure="${result.departureTime}" data-time-arrival="${result.arrivalTime}" class="btn btn-info text-white selectFlight">Selecionar</button>
            </div>
          </div>`
          results.insertAdjacentHTML("beforeend", flightCard);
          const selectFlight = document.getElementById(`${result.flightNumber}`)
          selectFlight.addEventListener('click', (event) => {
            const from = event.target.dataset.departure
            const to = event.target.dataset.arrival
            const time = event.target.dataset.timeDeparture
            const arrivalTime = event.target.dataset.timeArrival
            const stops = event.target.dataset.stops


            const fromInput = document.getElementById('travel_origin');
            const toInput = document.getElementById('travel_destination');
            const departureTimeInput = document.getElementById('travel_departure');
            const arrivalTimeInput = document.getElementById('travel_arrival');
            const travelDescriptionInput = document.getElementById('travel_description');

            const closeBtn = document.getElementById('closeBtn');
            closeBtn.click();

            fromInput.value = from
            toInput.value = to
            arrivalTimeInput.value = `${arrivalTime.split('T')[0]} ${arrivalTime.split('T')[1].split('.000')[0]}`
            departureTimeInput.value = `${time.split('T')[0]} ${time.split('T')[1].split('.000')[0]}`

            travelDescriptionInput.insertAdjacentHTML('beforeend', `<div>Escalas: ${stops}</div>`)
          });
      });
    }
    document.getElementById('search-flight').disabled = false;
  })
  .catch((error) => console.log(error));
}

const handleFlightForm = () => {
  const form = document.getElementById('flightForm');
  if (form){
    form.addEventListener('submit', (event) => {
      event.preventDefault();
      const company_code = document.getElementById('flight_company').value;
      const flight_number = document.getElementById('flight_fly_number').value;
      const date = document.getElementById('flight_date').value;
      const day = date.slice(0, 2);
      const month = date.slice(3, 5);
      const year = date.slice(6, 11);
      fetchFlightStats(company_code, flight_number, day, month, year);
    });
  }
}

export { flightOption, handleFlightForm }
