const activeSide = event => {
    const trItinerary = document.querySelectorAll(".tr-itinerary");
    const linkItinerary = document.querySelectorAll(".link-itinerary");
    linkItinerary.forEach(itinerary => {
        itinerary.addEventListener("click", event => {
            trItinerary.forEach(it => {
                it.classList.remove('active');
            });
            event.currentTarget.parentElement.parentElement.classList.add('active');
        });
    });
};

export { activeSide };
