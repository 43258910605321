import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
	static targets = ['entries', 'pagination'];

	initialize() {
		const options = {
			rootMargin: '100px',
		};

		this.intersectionObserver = new IntersectionObserver(
			entries => this.processIntersectionEntries(entries),
			options
		);
	}

	connect() {
		this.intersectionObserver.observe(this.paginationTarget);
		this.paginationTarget.classList.add('invisible');
	}

	disconnect() {
		this.intersectionObserver.unobserve(this.paginationTarget);
	}

	processIntersectionEntries(entries) {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				this.loadMore();
			}
		});
	}

	loadMore() {
		const next_page = this.paginationTarget.querySelector("a[rel='next']");
		if (next_page === null) {
			this.paginationTarget.classList.add('d-none');
			return;
		}
		const url = next_page.href;

		Rails.ajax({
			type: 'GET',
			url: url,
			dataType: 'json',
			success: data => {
				this.entriesTarget.insertAdjacentHTML('beforeend', data.entries);
				this.paginationTarget.innerHTML = data.pagination;
			},
		});
	}
}
