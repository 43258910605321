import { Controller } from 'stimulus';

export default class extends Controller {
	static targets = ['input', 'inputPrice', 'arrowUp', 'arrowDown'];

  static values = {
    itinerary: Number,
    pageGo: String,
    pageBack: String,
    page: String,
  }

  connect() {
    window.addEventListener("onbeforeunload", this.onLoadHandler());
  }

  // get position from where the user pinned the disponibility
  getScrollPosition() {
    const scrollPosition = window.scrollY || window.pageYOffset;
    sessionStorage.setItem('scrollPosition', scrollPosition);
  }

  // when page is loaded, set it to the same position as stored
  // when disponibility was pinned
  onLoadHandler() {
    const scrollPosition = sessionStorage.getItem('scrollPosition');
    const checkedCompanies = sessionStorage.getItem('checkedCompanies');
    const priceOrder = sessionStorage.getItem('priceOrder');

    if (scrollPosition !== null) {
      window.scrollTo(0, scrollPosition);
      // sessionStorage.removeItem('scrollPosition');
    } else {
      window.scrollTo(0, 0);
    }

    if (checkedCompanies) {
      this.inputTargets.forEach((input) => {
        const companyName = input.name.match(/\[(.*?)\]/)[1];

        if (checkedCompanies.includes(companyName)) {
          input.checked = true;
        }
      })
    }

    if (priceOrder && priceOrder === '1') {
      this.inputPriceTarget.checked = true
      this.arrowUpTarget.classList.add('d-none');
      this.arrowDownTarget.classList.remove('d-none');
    }
  }

  moveToGo() {
    const scrollPosition = 0;
    sessionStorage.setItem('scrollPosition', scrollPosition);
  }

  moveToBack() {
    const scrollPosition = document.getElementById("back-way").offsetTop;
    sessionStorage.setItem('scrollPosition', scrollPosition);
  }

  addPagesToBaseUrl(baseUrl) {
    if (this.pageGoValue) {
      baseUrl += `page_go=${this.pageGoValue}&`
    }

    if (this.pageBackValue) {
      baseUrl += `page_back=${this.pageBackValue}&`
    }

    if (this.pageValue) {
      baseUrl += `page=${this.pageValue}&`
    }

    return baseUrl
  }

  filterCompany() {
    let baseUrl = `/itineraries/${this.itineraryValue}/show-disp?`;
    let checkedCompanies = [];

    this.inputTargets.forEach((input) => {
      if (input.checked) {
        const companyName = input.name.match(/\[(.*?)\]/)[1];
        baseUrl += `company[]=${companyName}&`;
        checkedCompanies.push(companyName);
      }

      sessionStorage.setItem('checkedCompanies', checkedCompanies);
    })

    if (this.inputPriceTarget.checked) {
      baseUrl += "price=1&";
      this.arrowUpTarget.classList.add('d-none');
      this.arrowDownTarget.classList.remove('d-none');

      sessionStorage.setItem('priceOrder', 1);
    } else {
      this.arrowUpTarget.classList.remove('d-none');
      this.arrowDownTarget.classList.add('d-none');

      sessionStorage.removeItem('priceOrder');
    }

    window.location.href = this.addPagesToBaseUrl(baseUrl);
  }
}
