import flatpickr from "flatpickr";
import { Portuguese } from "flatpickr/dist/l10n/pt.js"

const initFlatpickr = () => {

  flatpickr(".datepickerTime", {
    allowInput: true,
    altInput: true,
    enableTime: true,
    altFormat: "j, F Y - H:i",
    locale: Portuguese
  });

  flatpickr(".datepickerDate", {
    altInput: true,
    altFormat: "j, F Y",
    allowInput: true,
    dateFormat: "d/m/Y",
    locale: Portuguese

  });

  flatpickr(".datepickerFlightDate", {
    altInput: false,
    altFormat: "j, F Y",
    allowInput: false,
    dateFormat: "d/m/Y",
    locale: Portuguese,
    minDate: "today",
    maxDate: new Date().fp_incr(366) // a year from now

  });
}

const initDatepicker = () => {

}

export { initFlatpickr };
