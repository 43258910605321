let name;

const handleChoice = (autocomplete) => {

  const email = document.getElementById('room_email');
  const address = document.getElementById('room_address');
  const phone = document.getElementById('room_phone');
  const kind = document.getElementById('room_kind');
  const stars = document.getElementById('room_stars');
  
  const info = autocomplete.getPlace();
  name.value = info.name;
  address.value = info.vicinity;
  phone.value = info.international_phone_number;
  kind.value = 'hotel';
  stars.value = info.rating;
  
  email.focus();
    
}

const initPlaces = () => {
  name = document.getElementById("room_name")
  if (name) {
    const options = {
      fields: ["name", "rating", "international_phone_number", "vicinity"],
      types: ["establishment"]
    };
    const autocomplete = new google.maps.places.Autocomplete(name, options);

    // when user chooses a place, this event is triggered
    autocomplete.addListener('place_changed', () => { handleChoice(autocomplete) });

    // avoid the form from submiting when pressing enter to confirm the place
    name.addEventListener('keydown', (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    })
  }
}

export { initPlaces }