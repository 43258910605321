import { Controller } from "stimulus";
import Sortable from 'sortablejs';
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ['orderable']

  connect() {
    this.sortable = Sortable.create(this.element, {
      animation: 150,
      ghostClass: 'sortable-ghost',
      chosenClass: "sortable-chosen",
      swapThreshold: 1,
      onEnd: this.end.bind(this),
      forceFallback: true
    })
  }

  end(event) {
    let data = new FormData()
    let objHash = {}

    Array.from(this.orderableTargets).forEach((element, index) => {
      objHash[element.id] = index
    });

    data.append("objectHash", JSON.stringify(objHash))

    Rails.ajax({
      url: this.data.get("url"),
      type: 'PATCH',
      data: data
    })
  }
};
