import Sortable from "sortablejs";

const token = document.getElementsByName("csrf-token")[0].content;

const saveDaysOrder = () => {
  const newList = document.querySelectorAll(".day-list");

  // Mapeie newList para um array de promises fetch
  const fetchPromises = Array.from(newList).map((item, index) => {
    const dayId = item.dataset.dayId;
    const newOrder = index;
    const url = `/days/${dayId}/set_new_order`;

    return fetch(url, {
      method: "PATCH",
      headers: { "Content-Type": "application/json", "X-CSRF-Token": token },
      body: JSON.stringify({ display_order: newOrder }),
    });
  });

  // Aguarde todas as promises fetch concluírem, em seguida, recarregue a página
  Promise.all(fetchPromises)
    .then(() => location.reload())
    .catch((error) => console.error("Error:", error));
};

const initSortable = () => {
  const list = document.querySelector("#days-list");
  if (list) {
    Sortable.create(list, {
      ghostClass: "ghost",
      animation: 150,
      onUpdate: saveDaysOrder,
    });
  }
};

export { initSortable };
