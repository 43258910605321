const previewImageOnFileSelect = () => {
  // select the photo input
  const input = document.getElementById('photo-input');
  if (input) {
    // add a listener to know when a new picture is uploaded
    input.addEventListener('change', () => {
      // call the displayPreview function (who retrieve the image url and display it)
      displayPreview(input);
    });
  }
}

const displayPreview = (input) => {
  if (input.files) {
    // get the div where the photos will be inserted
    const photos = document.getElementById('photo-preview')
    photos.innerHTML = ''
    Object.values(input.files).forEach((photo) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const image = `<img src='${event.currentTarget.result}' class="photos-preview">`
        photos.insertAdjacentHTML('beforeend', image)
      }
      reader.readAsDataURL(photo);
    });
  }
}

export { previewImageOnFileSelect };
