import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['rav', 'du', 'fee', 'emb', 'mkp', 'tar', 'price']

  static values = {
    itinerary: Number
  }

  initialize() {
    this.token = document.getElementsByName("csrf-token")[0].content;
  }

  makeAllVisible(e) {
    const icon = e.currentTarget;

    const visibilityMethod = icon.classList.contains('fa-eye-slash') ? 'turn_visible' : 'turn_unvisible';

    this.updateVisibility(icon.id, visibilityMethod);

    ['rav', 'du', 'fee', 'emb', 'mkp', 'tar'].forEach((target) => {
      this.updateVisibility(target, visibilityMethod);
    });
  }

  makeVisible(e) {
    const icon = e.currentTarget;

    if (icon.classList.contains('fa-eye-slash')) {
      this.updateVisibility(icon.id, 'turn_visible');
    } else {
      this.updateVisibility(icon.id, 'turn_unvisible');
    }
  }

  async updateVisibility(iconId, visibilityMethod) {
    const attrMap = {
      'rav': 'visible_rav',
      'fee': 'visible_fee',
      'du': 'visible_du',
      'emb': 'visible_boarding',
      'tar': 'visible_fare',
      'mkp': 'visible_mkp',
      'price': 'visible_total_price'
    };

    const targetsMap = {
      'rav': this.ravTargets,
      'fee': this.feeTargets,
      'du': this.duTargets,
      'emb': this.embTargets,
      'tar': this.tarTargets,
      'mkp': this.mkpTargets,
      'price': this.priceTargets
    };

    const attr = attrMap[iconId];

    try {
      const url = `${window.location.origin}/itineraries/${this.itineraryValue}/${visibilityMethod}`;
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.token
        },
        body: JSON.stringify({attribute: attr})
      });

      if (!response.ok) {
        throw new Error('Failed to update attributes');
      }

      const data = await response.json();

      if (visibilityMethod === 'turn_visible') {
        targetsMap[iconId].forEach((target) => { this.updateClassesVisible(target) })
      } else {
        targetsMap[iconId].forEach((target) => { this.updateClassesUnvisible(target) })
      }
    } catch (error) {
      console.error(error);
    }
  }

  updateClassesVisible(target) {
    target.children[0].classList.remove('fa-eye-slash');
    target.children[0].classList.add('fa-eye');
    target.style.color = "#545454";
  }

  updateClassesUnvisible(target) {
    target.children[0].classList.remove('fa-eye');
    target.children[0].classList.add('fa-eye-slash');
    target.style.color = "#BBBBBB";
  }
}
